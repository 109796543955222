<!-- Employees -->
<template>
    <div class="card card-custom" v-loading="loading">
        <div class="card-body p-0">
            <!--begin: Employees-->
            <div class="wizard wizard-1" id="kt_wizard_v1" data-wizard-state="step-first" data-wizard-clickable="true">
                <!--begin: Employees Body-->
                <div class="row justify-content-center">
                    <div class="col-xl-12">
                        <!--begin: Wizard Form-->
                        <el-form ref="form" :model="form" auto-complete="nope">
                            <!--begin: Wizard Step 1-->
                            <div class="pb-5" data-wizard-type="step-content" data-wizard-state="current">
                                <h3 class="mb-10 font-weight-bold text-dark">
                                    <i class="flaticon-rotate"></i> {{title_text}} 
                                </h3>
                                <div class="row">
                                    <div class="col-xl-9">
                                        <div class="row">
                                             <div class="col-xl-2">
                                                  <el-form-item
                                                    label="Employee ID"
                                                    prop="emp_id"
                                                  >
                                                    <el-input
                                                      v-model="form.emp_id"
                                                      auto-complete="nope"
                                                      disabled                                                     
                                                    ></el-input>
                                                  
                                                  </el-form-item>
                                                </div>
                                            <div class="col-xl-3">
                                                <el-form-item label="First Name" prop="first_name">
                                                   <el-input v-model="form.first_name" @input.native="capitalize" auto-complete="nope" :class="form.errors.has('first_name') ? 'error required':'required'"></el-input>
                                                </el-form-item>
                                                <span class="form-err" v-if="form.errors.has('first_name')" >{{form.errors.get('first_name')}}</span>
                                            </div>
                                            <div class="col-xl-3">
                                                <el-form-item label="Last Name" prop="last_name">
                                                   <el-input v-model="form.last_name" @input.native="capitalize" auto-complete="nope" ></el-input>
                                                </el-form-item>                                               
                                            </div>
                                            <div class="col-xl-4">
                                                <el-form-item label="Email" prop="email">
                                                   <el-input v-model="form.email" auto-complete="nope" ></el-input>
                                                </el-form-item>
                                                 <span class="form-err" v-if="form.errors.has('email')" >{{form.errors.get('email')}}</span> 
                                            </div>
                                        </div>
                                        <div class="row">                                            
                                            <div class="col-xl-4">
                                                <el-form-item label="Phone" prop="phone_number">
                                                   <el-input v-model="form.phone_number" auto-complete="nope"   @keyup.native="phoneNumberValidation"   class="phone_check"><template slot="prepend">+1</template></el-input>
                                                </el-form-item>
                                                <span class="form-err" >{{form.errors.get('phone_number')}}</span>
                                            </div>
                                            <div class="col-xl-4">
                                                <el-form-item label="Address" prop="street">
                                                   <vue-google-autocomplete ref="street"  v-model="form.street" id="map" classname="form-control el-input__inner" name="street" placeholder="Enter your Address" v-on:placechanged="getAddressData" country="ca"
                                                    onfocus="this.setAttribute('autocomplete', 'disable-autocomplete');">
                                                   </vue-google-autocomplete>                                                   
                                                </el-form-item>
                                            </div> 
                                            <div class="col-xl-4">
                                                <el-form-item label="Unit No" prop="unit_number">
                                                   <el-input v-model="form.unit_number" @input.native="capitalize" auto-complete="nope" :class="form.errors.has('unit_number') ? 'error ':''"></el-input>
                                                </el-form-item>
                                            </div>                                      
                                        </div>
                                        <div class="row">  
                                           
                                           <div class="col-xl-4">
                                                <el-form-item label="Province" prop="state">
                                                   <el-select filterable ref="state" v-model="form.state_id" @change="getCityListByStateId('billing')" :class="form.errors.has('state') ? 'error ':''" clearable @clear="stateClear()">
                                                      <el-option v-for="(state,index) in state_list" :key="index" :label="state.state_name" :value="state.state_id">
                                                      </el-option>
                                                   </el-select>
                                                </el-form-item>
                                            </div>
                                             <div class="col-xl-4">
                                                <el-form-item label="City" prop="city">
                                                   <el-select filterable ref="city" v-model="form.city_id" :class="form.errors.has('city') ? 'error ':''" clearable>
                                                      <el-option v-for="(city,index) in city_list" :key="index" :label="city.city_name" :value="city.city_id">
                                                      </el-option>
                                                   </el-select>
                                                </el-form-item>
                                            </div> 
                                            <div class="col-xl-4">
                                                <el-form-item label="Postal Code" prop="postal_code">
                                                   <el-input v-model="form.postal_code"  autocomplete="off" placeholder="M1S 3A7" @change="validPostal(form.postal_code)"></el-input>
                                                </el-form-item>
                                                 <span class="form-err" v-if="form.errors.has('postal_code')" >{{form.errors.get('postal_code')}}</span>
                                            </div>                                             
                                        </div>
                                        <div class="row"> 
                                          <div class="col-xl-4" v-if="!this.$route.params.employeeId">
                                                <el-form-item label="Password" prop="password">                                                  
                                                   <el-input v-model="form.password"  autocomplete="off" :class="form.errors.has('password') ? 'error required':'required'" show-password></el-input>
                                                </el-form-item>
                                                <span class="form-err" v-if="form.errors.has('password')" >{{form.errors.get('password')}}</span>
                                            </div>                                          
                                            <div class="col-xl-4" v-if ="!this.$route.params.employeeId">
                                                <el-form-item label="Confirm Password" prop="c_password">
                                                   <el-input v-model="form.c_password"  autocomplete="off" :class="form.errors.has('c_password') ? 'error required':'required'" show-password></el-input>
                                                </el-form-item>
                                                <span class="form-err" v-if="form.errors.has('c_password')" >{{form.errors.get('c_password')}}</span>
                                            </div>

                                            <div class="col-xl-4">
                                                <el-form-item label="Role" prop="role_id">
                                                   <el-select ref="role_id" v-model="form.role_id" :class="form.errors.has('role_id') ? 'error ':''" clearable autocomplete="off">
                                                      <el-option v-for="(role, index) in role_list" :key="index" :label="role.role" :value="role.id" :disabled="role.role == 'Super Admin'?user.role_id != 1:false">
                                                      </el-option>
                                                   </el-select>
                                                </el-form-item>
                                                <span class="form-err" v-if="form.errors.has('role_id')" >The role field is required.</span>
                                            </div>
                                             <div class="col-xl-4">
                                                <el-form-item label="Employee CheckIn ID" prop="employee_check_id">
                                                   <el-input v-model="form.employee_check_id" autocomplete="off" disabled                                                   
                                                   ></el-input>
                                                </el-form-item>                                                
                                            </div>
                                            <div class="col-xl-4">
                                                <el-form-item label="Status" prop="active">
                                                   <el-select ref="active" v-model="form.active" :class="form.errors.has('active') ? 'error ':''">
                                                      <el-option v-for="(option, index) in options" :key="index" :label="option.text" :value="option.id">
                                                      </el-option>
                                                   </el-select>
                                                </el-form-item>                                                
                                            </div>                                            
                                            <div class="col-xl-8">                                              
                                                <el-switch :active-value="1" :inactive-value="0" active-text="Enable Credit Approve Permission ?" v-model="form.credit_access" style="margin:29px 0px 0px"></el-switch>                                            
                                            </div>

                                        </div>
                                    </div>
                                    <div class="col-xl-3">
                                        <div class="row">
                                            <div class="col-xl-12">
                                                <div class="form-group mt-6 mb-0">
                                                    <div class="field text-center">
                                                        <div class="preview-container">
                                                            <img v-if="img_preview" :src="img_preview"/>
                                                        </div>                     
                                                    </div>
                                                    <div class="field text-center">
                                                        <div class="upload-btn-container">
                                                            <a href="#" class="add-logo-btn"  style="width: 60%;">
                                                              <span v-if="form.image_btn == 0">Add Profile Pic</span>
                                                              <span v-else>Edit Profile Pic</span>                                                           
                                                            <input type="file" @change="onFileChange"  accept="image/gif, image/jpeg">
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!--begin: Employees Actions -->
                            <div class="d-flex justify-content-between border-top pt-10">
                               <a @click="createEmployee" class="text-white">
                                    <div class="btn btn-primary font-weight-bold text-uppercase">
                                        {{submit_btn_text}}
                                    </div>
                                </a>
                            </div>
                            <!--end: Employees Actions -->
                        </el-form>
                        <!--end: Employees Form-->
                    </div>
                </div>
                <!--end: Employees Body-->
            </div>
        </div>
        <!--end: Employees-->
    </div>
</template>



<style>
.settingicon{font-size:20px;}
select.select2{opacity:1;}
.preview-container img{max-width:200px;}
.add-logo-btn {margin-left:40px;}
</style>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Form from '@/core/services/api.form.services'
import { getCityList, getStateList, getCityListByStateId,getStateByPrefix, getCityByName } from '@/api/common'
import {getEmployeeDetails, getRoleList,getPasswordCheck,getEmployeeId,getEmployeeCheckInId} from "@/api/employee";
import JwtService from "@/core/services/jwt.service";
import VueGoogleAutocomplete from "vue-google-autocomplete";

export default {
  name: 'add-employee',
    data() {
    return {
      loading:false,
     // error:null,
     update_pass: 0,
      form : new Form({        
        first_name : null,
        last_name: null,
        email : null,
        password : null,
        c_password: null,
        phone_number : null,
        address : null,
        city_id : null,
        state_id : 8,
        role_id : null,
        postal_code : null,
        profile_image : null,
        active : 1,
        credit_access: 0,
        unit_number: null,
        street: null,
        employee_check_id:null,
        emp_id:null,
        image_btn:0
      }),
     // loginbg: require('@/assets/images/niiyo-cricket-logo-dark.png'),
      city_list: [],
      state_list: [],
      role_list: [],
      user: [],
      img_preview: '/./media/bg/profile-img.png',
      logo_btn_text: null,
      submit_btn_text: 'Save',
      title_text: 'Add Employee',
      api_url: '/user/create',
      response_msg: 'Employee created successfully.',
       options: [
            { id: 1, text: "Active" },
            { id: 0, text: "In Active" }
        ]
    }
  },
   components: {
   VueGoogleAutocomplete 
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Dashboard", route: "/dashboard" },
      { title: "Employees", route: "/employee/list" },
      { title: "Add/Edit Employee" }
    ]);
  },
  methods: {
   stateClear(){
     this.form.state_id ='';
     this.form.city_id = '';
   },
    createEmployee: function() {     
      this.loading=true;
      this.form.post(this.api_url).then((response) => {
        if(response.status){
            this.$router.push({ path: '/employee/list' })
            this.loading=false;
            this.$showResponse('success', response.message);
        }
      }).catch(()=>{
        this.loading=false;
      });
    },
     getAddressData: function (addressData) {                  
          this.form.postal_code = addressData.postal_code;
          this.validPostal(this.form.postal_code);   
	        var city_name = addressData.locality;
	        if(city_name != null){
	        	this.getCityIdByName(city_name);
	        }
	        var state_prefix = addressData.administrative_area_level_1;
	        if(state_prefix != null){
	          this.getStateByStatePrefix(state_prefix);
	        }	       
	        this.form.street = addressData.street_number+' '+addressData.route;         
          
	        this.address = addressData;
      
      },
     getStateByStatePrefix(state_prefix) {     
        getStateByPrefix(state_prefix).then((response) => {       
            this.form.state_id = response.data.data.state_id;          
        });
    },
     getCityIdByName(city_name) {     
        getCityByName(city_name).then((response) => {
            this.city_list = response.data.city_list;
            this.form.city_id = response.data.data.city_id;     
        });
      },
     formatPhoneNumber(phoneNumberString) {                     
         var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
         var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);        
         if (match) {
            var intlCode = (match[1] ? '+1 ' : '');
            return [intlCode,  match[2], '-', match[3], '-', match[4]].join('');
         }
         return null;
         },
          phoneNumberValidation:function(event) { 
            if (['Arrow', 'Backspace', 'Shift'].includes(event.key)) {
            		this.preventNextIteration = true;
                return;
            }
            if (this.preventNextIteration) {
		            this.preventNextIteration = false;
            		return;
            }
            this.phoneValue = this.form.phone_number.replace(/[^0-9]+/g, '');

            this.form.phone_number = this.phoneValue.replace(/(\d{1,3})(\d{1,3})(\d{1,4})/g, '$1-$2-$3').slice(0,12);
            this.phone1_error = "";
               
          },
     checkZip(value) {
            return (/^(?!.*[DFIOQU])[A-VXY][0-9][A-Z] ?[0-9][A-Z][0-9]$/).test(value);
         },
     getEmployeeId() {
          getEmployeeId().then((response) => {
            this.form.emp_id = response.data;
          });
     },  

     getEmployeeCheckInId() {
          getEmployeeCheckInId().then((response) => {
            this.form.employee_check_id = response.data;
          });
     
    },
    validPostal(postalcode){   
             let postal_code = postalcode.toUpperCase(); 
              this.form.errors.add('postal_code' ,'') 
             if (this.checkZip(postal_code)) {
                  this.form.postal_code = postal_code.replace(/\W/g,'').replace(/(...)/,'$1 ');
               } else {
                  console.log(postal_code);
                  if(postal_code != ''){
                   // this.form.errors.add('postal_code' ,'Invalid Postal Code')                                 
                    this.form.postal_code = '';
                 }
               }
          },
    onFileChange(e) {
      let files = e.target.files || e.dataTransfer.files;

      if (files.length <= 0) {
        return;
      }

      this.form.profile_image = files[0];
      this.img_preview = URL.createObjectURL(files[0]);
    },
    getCityListByStateId(){
      this.loading = true
      getCityListByStateId(this.form.state_id).then(response => {
        this.form.city_id = null,
        this.city_list = response.data.data
        this.loading = false
      })
    },
    getCityList(){
      this.loading = true
      getCityList().then(response => {
        this.city_list = response.data.data
        this.loading = false
      })
    },
    getStateList(){
      this.loading = true
      getStateList().then(response => {
        this.state_list = response.data.data
        this.loading = false
      })
    },
    getRoleList(){
      this.loading = true
      getRoleList().then(response => {
        this.role_list = response.data.data
        this.loading = false
      })
    },
    viewAllEmployees(){
      this.$router.push({path:'/employee/list'})
    },
   
     getPasswordCheck(password) {      
      this.email_loading = true;
       this.form.errors.add("employee_check_id",'');
      getPasswordCheck(password)
        .then((response) => {
          this.email_error = "";
          this.email_loading = false;
          if (response.status === true) {
            return;
          }
        })
        .catch((error) => {         
          this.form.errors.add(
            "employee_check_id",
            error.data.error.employee_check_id[0]
          );
         
        });
    },

  },
  created(){
     
    this.getStateList();
    this.getCityList();
    this.getRoleList();
    
    let employeeId = this.$route.params.employeeId;
    if(JwtService.getAttribute('user_data')){
        this.user = JSON.parse(JwtService.getAttribute('user_data'));        
      }
    this.logo_btn_text = 'Add Logo'
    if(parseInt(employeeId) > 0){
      this.logo_btn_text = 'Update Logo'
      this.submit_btn_text = 'Update'
      this.title_text = 'Update Employee'
      this.action = 'update'
      this.response_msg = 'Employee updated successfully.'

      getEmployeeDetails(this.$route.params.employeeId).then(response => {
        if(response.data.data.id){
          this.api_url = 'user/update/' + response.data.data.id
        }
        this.form = new Form(response.data.data)
        this.update_pass = 1;        
        if(response.data.data.profile_image){
          this.img_preview = process.env.VUE_APP_BASE_IMAGE_URL + response.data.data.profile_image
          this.form.image_btn = 1;
        }
      });
    }else{
      this.getEmployeeId();
      this.getEmployeeCheckInId();
    }
   
  }

}
</script>
<style >
.el-input-group__prepend{
  padding: 0px 8px !important;
  /* border-left: 2px solid red !important; */
}
.phone_check .el-input__inner{
border-bottom-left-radius :0px;
border-top-left-radius : 0px;

}
</style>

